<template>
  <div class="container p-0 unselectable">
    <div class="position-relative d-block pt-4 pb-4 px-3 bg-white">
      <div class="d-flex justify-content-center align-items-center">
        <div class="img-container">
          <!-- <img /> -->
          <div class="rounded-circle bg-light border text-secondary avatar">
            A
          </div>
        </div>
      </div>
      <div class="mt-3 mb-4 text-center">
        <h6>Dr Aminah</h6>
        <p class="small text-muted">No Lesen KJA42872</p>
      </div>
      <div
        class="
          mt-4
          position-relative
          d-flex
          justify-content-center
          align-items-center
        "
      >
        <div class="card mr-2 bg-light">
          <div class="card-body">
            <h4>12</h4>
            <p class="m-0 small text-muted">Consultation</p>
          </div>
        </div>
        <div class="card ml-2 bg-light">
          <div class="card-body">
            <h4>5</h4>
            <p class="m-0 small text-muted">E-RX Issued</p>
          </div>
        </div>
      </div>
    </div>
    <!-- navigation -->
    <div class="border-top">
      <ul class="list-group rounded-0">
        <li class="list-group-item border-bottom border-top-0 border-right-0 border-left-0">
          <div class="d-flex justify-content-between align-items-center">
            <div class="mr-2 d-flex">
              <i class="ri-user-3-line align-middle mr-2"></i>
              <p class="m-0">Personal information</p>
            </div>
            <i class="ri-arrow-right-s-line align-middle"></i>
          </div>
        </li>
        <li class="list-group-item border-bottom border-top-0 border-right-0 border-left-0">
          <div class="d-flex justify-content-between align-items-center">
            <div class="mr-2 d-flex">
              <i class="ri-lock-line align-middle mr-2"></i>
              <p class="m-0">Password Setting</p>
            </div>
            <i class="ri-arrow-right-s-line align-middle"></i>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "me",
};
</script>

<style scoped>
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.avatar {
  padding: 1rem 1.5rem;
  font-size: 3rem;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-container {
  height: 100px;
  width: 100px;
  position: relative;
  display: block;
  margin-bottom: 1rem;
  margin-top: 5rem;
}
</style>